import Env from "../../services/Env";
import {
  getList,
  getMsList,
  getSubList,
  makeCategory,
  updateCategory,
  updateMsCategory,
} from "../../services/Category";

import {
  storeData,
  retrieveData,
  safelyParseJSON,
} from "../../services/Storage";

const moduleName = "category";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_FILTERED_LIST = `${moduleName}/SET_FILTERED_LIST`;
const SET_MS_LIST = `${moduleName}/SET_MS_LIST`;
const SET_SUB_LIST = `${moduleName}/SET_SUB_LIST`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
  list: [],
  subList: [],
  msList: [],
  filteredList: [],
  error: false,
  errorType: 3,
  isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_LIST:
      return { ...state, list: payload };
    case SET_FILTERED_LIST:
      return { ...state, filteredList: payload };
    case SET_MS_LIST:
      return { ...state, msList: payload };
    case SET_SUB_LIST:
      return { ...state, subList: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_ERROR_TYPE:
      return { ...state, errorType: payload };

    case SET_API_REQUEST:
      console.log("fetching API request", payload);
      return { ...state, isFetching: payload };

    default:
      return state;
  }
};

export const setList = (value) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LIST,
      payload: value,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSubCategoryList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });
    return getSubList().then((res) => {
      if (res.response) {
        if (res.response.length > 0)
          res.response.unshift({ id: 0, title: " - " });
          dispatch({
            type: SET_SUB_LIST,
            payload: res.response,
          });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCategoryList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getList().then((res) => {
      if (res.response) {
        let arr = [],
          arr2 = [[]],
          arrResult = [];
        res.response.map((item) => {
          item.list = [];
          if (item.root_category_id == null) arr.push(item);
        });

        res.response.map((item) => {
          if (item.root_category_id != null) {
            if (arr2[item.root_category_id]) {
              arr2[item.root_category_id].push(item);
            } else {
              arr2[item.root_category_id] = [];
              arr2[item.root_category_id].push(item);
            }
          }  else {
            arr2[0].push(item);
          }
        });
        arr.map((item) => {
          arrResult.push(item);
          if (arr2[item.id]) {
            arr2[item.id].map((item2) => {
              arrResult.push(item2);
            });
          }
        }); 

        let filteredList  = [];
        let tempArr =  res.response;
        res.response.map((item) => {
          let f = null;
          tempArr.map((item2, index) => {
            if(item.id == item2.root_category_id) {
              item.list.push(item2);
              f = index; 
            }
          }); 
        });

        /*
        arr.map((item) => { 
          res.response.map((item2, index) => {
            if(item.id == item2.root_category_id) {
              item.list.push(item2);
            }
          }); 
        });
        */

        dispatch({
          type: SET_FILTERED_LIST,
          payload: arr,
        });


        dispatch({
          type: SET_LIST,
          payload: arrResult,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const getMsCategoryList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return getMsList().then((res) => {
      if (res.response) {
        dispatch({
          type: SET_MS_LIST,
          payload: res.response,
        });
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const addCategory = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return makeCategory(getState().login.user.api_token, _data).then((res) => {
      console.log(res);
      if (res.response) {
        dispatch(getCategoryList());
      }
      dispatch({
        type: SET_API_REQUEST,
        payload: false,
      });
      return res.response;
    });
  } catch (error) {
    console.error(error);
  }
};

export const update = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateCategory(getState().login.user.api_token, _data).then(
      (res) => {
        console.log(res);
        if (res.response) {
          dispatch(getCategoryList());
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateMs = (_data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_API_REQUEST,
      payload: true,
    });

    return updateMsCategory(getState().login.user.api_token, _data).then(
      (res) => {
        console.log(res);
        if (res.response) {
          dispatch(getMsCategoryList());
        }
        dispatch({
          type: SET_API_REQUEST,
          payload: false,
        });
        return res.response;
      }
    );
  } catch (error) {
    console.error(error);
  }
};
