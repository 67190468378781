import React from "react";
 

import c_01 from "../../assets/images/category/category_01.webp";
import c_02 from "../../assets/images/category/category_02.webp";
import c_03 from "../../assets/images/category/category_03.webp";
import c_04 from "../../assets/images/category/category_04.webp";
import c_05 from "../../assets/images/category/category_05.webp";
import c_06 from "../../assets/images/category/category_06.webp";
import c_07 from "../../assets/images/category/category_07.webp";
import c_08 from "../../assets/images/category/category_08.webp";
import c_09 from "../../assets/images/category/category_09.webp";
import c_10 from "../../assets/images/category/category_10.webp";
import c_11 from "../../assets/images/category/category_11.webp";
import c_12 from "../../assets/images/category/category_12.webp";
import c_13 from "../../assets/images/category/category_13.webp";
import c_14 from "../../assets/images/category/category_14.webp";
import c_15 from "../../assets/images/category/category_15.webp";
import c_16 from "../../assets/images/category/category_16.webp";
import c_17 from "../../assets/images/category/category_17.webp";
import c_18 from "../../assets/images/category/category_18.webp";
import c_19 from "../../assets/images/category/category_19.webp";
import c_20 from "../../assets/images/category/category_20.webp";
import c_21 from "../../assets/images/category/category_21.webp";
import c_22 from "../../assets/images/category/category_22.webp";
import c_23 from "../../assets/images/category/category_23.webp";
import c_24 from "../../assets/images/category/category_24.webp";
import c_25 from "../../assets/images/category/category_25.webp";
import c_26 from "../../assets/images/category/category_26.webp";
import c_27 from "../../assets/images/category/category_27.webp";
import c_28 from "../../assets/images/category/category_28.webp";
import c_29 from "../../assets/images/category/category_29.webp";
import c_30 from "../../assets/images/category/category_30.webp";
import c_31 from "../../assets/images/category/category_31.webp";
import c_32 from "../../assets/images/category/category_32.webp";
import c_33 from "../../assets/images/category/category_33.webp";
import c_34 from "../../assets/images/category/category_34.webp";
import c_35 from "../../assets/images/category/category_35.webp";
import c_36 from "../../assets/images/category/category_36.webp";
import c_37 from "../../assets/images/category/category_37.webp";
import c_38 from "../../assets/images/category/category_38.webp";
import c_39 from "../../assets/images/category/category_39.webp";
import c_40 from "../../assets/images/category/category_40.webp";
import c_41 from "../../assets/images/category/category_41.png";
import c_42 from "../../assets/images/category/category_42.webp";
import c_43 from "../../assets/images/category/category_43.webp";
import c_44 from "../../assets/images/category/category_44.webp";
import c_45 from "../../assets/images/category/category_45.webp";
import c_46 from "../../assets/images/category/category_46.webp";
import c_47 from "../../assets/images/category/category_47.webp";
import c_48 from "../../assets/images/category/category_48.webp";
import c_49 from "../../assets/images/category/category_49.webp";
import c_50 from "../../assets/images/category/category_50.webp";
import c_51 from "../../assets/images/category/category_51.webp";
import c_52 from "../../assets/images/category/category_52.webp";
import c_53 from "../../assets/images/category/category_53.webp";
import c_54 from "../../assets/images/category/category_54.webp";
import c_55 from "../../assets/images/category/category_55.webp";
import c_56 from "../../assets/images/category/category_56.webp";
import c_57 from "../../assets/images/category/category_57.webp";
import c_58 from "../../assets/images/category/category_58.webp";

export default function UiIconCategory({ fill, ...props }) {
  return props.icon === "c_01" ? (
    <img
      src={c_01}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_02" ? (
    <img
      src={c_02}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_03" ? (
    <img
      src={c_03}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_04" ? (
    <img
      src={c_04}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_05" ? (
    <img
      src={c_05}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_06" ? (
    <img
      src={c_06}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_07" ? (
    <img
      src={c_07}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_08" ? (
    <img
      src={c_08}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_09" ? (
    <img
      src={c_09}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_10" ? (
    <img
      src={c_10}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_11" ? (
    <img
      src={c_11}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_12" ? (
    <img
      src={c_12}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_13" ? (
    <img
      src={c_13}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_14" ? (
    <img
      src={c_14}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_15" ? (
    <img
      src={c_15}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_16" ? (
    <img
      src={c_16}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_17" ? (
    <img
      src={c_17}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_18" ? (
    <img
      src={c_18}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_19" ? (
    <img
      src={c_19}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_20" ? (
    <img
      src={c_20}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_21" ? (
    <img
      src={c_21}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_22" ? (
    <img
      src={c_22}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_23" ? (
    <img
      src={c_23}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_24" ? (
    <img
      src={c_24}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_25" ? (
    <img
      src={c_25}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_26" ? (
    <img
      src={c_26}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_27" ? (
    <img
      src={c_27}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_28" ? (
    <img
      src={c_28}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_29" ? (
    <img
      src={c_29}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_30" ? (
    <img
      src={c_30}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_31" ? (
    <img
      src={c_31}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_32" ? (
    <img
      src={c_32}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_33" ? (
    <img
      src={c_33}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_34" ? (
    <img
      src={c_34}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_35" ? (
    <img
      src={c_35}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_36" ? (
    <img
      src={c_36}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_37" ? (
    <img
      src={c_37}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_38" ? (
    <img
      src={c_38}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_39" ? (
    <img
      src={c_39}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_40" ? (
    <img
      src={c_40}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_41" ? (
    <img
      src={c_41}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_42" ? (
    <img
      src={c_42}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_43" ? (
    <img
      src={c_43}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_44" ? (
    <img
      src={c_44}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_45" ? (
    <img
      src={c_45}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_46" ? (
    <img
      src={c_46}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_47" ? (
    <img
      src={c_47}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_48" ? (
    <img
      src={c_48}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_49" ? (
    <img
      src={c_49}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_50" ? (
    <img
      src={c_50}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_51" ? (
    <img
      src={c_51}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_52" ? (
    <img
      src={c_52}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_53" ? (
    <img
      src={c_53}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_54" ? (
    <img
      src={c_54}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_55" ? (
    <img
      src={c_55}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_56" ? (
    <img
      src={c_56}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_57" ? (
    <img
      src={c_57}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : props.icon === "c_58" ? (
    <img
      src={c_58}
      alt={props.title}
      title={props.title}
      width={props.size}
      height={props.size}
    />
  ) : null;
}
